export const content = {
  articles: [
    {
      title: "The colours of Tech Leadership",
      date: "August 30, 2022",
      image: "/images/colors-of-tech-leadership.jpeg",
      link: "the_colours_of_tech_leadership",
      summary:
        "Finding a good way to do Tech Leadership is tough. When dumbing down the problem for people I often refer the Insights Discovery personality colours (with a slightly different interpretation) and how a lack of one or more of the colours can affect the teams impact.",
    },
    {
      title: "Fun with flags",
      date: "September 16, 2022",
      image: "/images/fun_with_flags.png",
      link: "fun_with_flags",
      summary:
        "Having lived in 5 different countries, we recently moved back to the Netherlands where currently the upside down version of our flag is being used as a symbol of protest by farmers against the national government. It initially made me think of Sheldon's vlog \"fun with flags\" in the Big Bang Theory. It also made me think about what the national flags I've lived under represent for me.",
    },
    {
      title: "Finding Flow",
      date: "September 7, 2022",
      image: "/images/finding_flow.png",
      link: "finding_flow",
      summary:
        'Instead of referring to "Process", I prefer to refer to "Flow". Flow implies progress and is something you can maintain for a long time. Finding that flow for yourself, your team and/or your organisation is a nice challenge, but very rewarding when you find it !.',
    },
    {
      title: "Back to the (coding) rabbit holes",
      date: "May 25, 2022",
      image: "/images/back_to_the_coding_rabbit_holes.jpeg",
      link: "back_to_the_coding_rabbit_holes",
      summary:
        "I like getting up early and trying to catch the sunrise with my dog Harry. Usually I would then soon start my journey to work, but Covid changed all that. I decided to use the extra hour in the morning to get back into coding. It's been over 15 years since I was seriously coding so it was a bit of a challenge to actually practice what I preached for so long. Here's my main insights on how the developer experience changed.",
    },
  ],
};
