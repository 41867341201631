import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Carousel } from "react-bootstrap";
import "./custom.scss";
import roleOrServices from "./content/roleorservice.json"; // Assuming this array contains strings
import relevantExperience from "./content/relevantexperience.json";

import Connect from "./connect";


export default function Home() {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typingIndex, setTypingIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [responseText, setResponseText] = useState(""); // For displaying the response
  const [responseTypingIndex, setResponseTypingIndex] = useState(0); // For word-by-word typing in the response
  const [isTypingResponse, setIsTypingResponse] = useState(false); // To control when the response starts typing
  const carouselRef = useRef(null);

  // map the Need attribute in the roleOrServices to an array of strings (prompts)
  const promptArray = roleOrServices.map((roleOrService) => roleOrService.need);
  // map the Description attribute in the roleOrServices to an array of strings (responses)
  const responseArray = roleOrServices.map(
    (roleOrService) => roleOrService.description
  );

  // Handle the prompt typing effect
  useEffect(() => {
    if (isTyping) {
      if (typingIndex < promptArray[currentIndex].length) {
        const timeout = setTimeout(() => {
          setDisplayedText(
            (prev) => prev + promptArray[currentIndex][typingIndex]
          );
          setTypingIndex((prev) => prev + 1);
        }, 150); // Adjust typing speed here
        return () => clearTimeout(timeout);
      } else {
        // Once the prompt is fully typed, clear it and start typing the response
        setTimeout(() => {
          setIsTyping(false);
          setIsTypingResponse(true); // Start typing the response
        }, 1000); // Optional delay before clearing prompt and starting response
      }
    }
  }, [typingIndex, isTyping, promptArray, currentIndex]);

  // Handle the response typing effect word by word
  useEffect(() => {
    if (isTypingResponse) {
      const responseWords = responseArray[currentIndex].split(" "); // Split response into words
      if (responseTypingIndex < responseWords.length) {
        const timeout = setTimeout(() => {
          setResponseText(
            (prev) => prev + " " + responseWords[responseTypingIndex]
          );
          setResponseTypingIndex((prev) => prev + 1);
        }, 250); // Adjust speed for word-by-word typing
        return () => clearTimeout(timeout);
      } else {
        // Once the response is fully typed, reset everything for the next prompt-response cycle
        setTimeout(() => {
          setResponseText(""); // Clear the response
          setDisplayedText(""); // Clear the prompt
          setTypingIndex(0);
          setResponseTypingIndex(0);
          setCurrentIndex((prev) => (prev + 1) % promptArray.length); // Move to the next prompt
          setIsTyping(true); // Start typing the next prompt
          setIsTypingResponse(false); // Stop response typing
        }, 4000); // Delay before clearing response and starting next prompt
      }
    }
  }, [
    isTypingResponse,
    responseTypingIndex,
    responseArray,
    currentIndex,
    promptArray.length,
  ]);

  return (
    <>
      <Container className="bg-light full-height" fluid>
        <Row className="w-100">
          <Col
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center text-center"
          >
            <img
              src="./images/rick_1024.jpg"
              alt="Rick Molenaar"
              className="circular-image mb-3"
            />
            <h1 className="text-accent1">Rick Molenaar</h1>
            <p className="text-accent1">
              Orchestrating Transitions | Senior Software Engineering Leader,
              Coach & Mentor, Available for Interim and Fractional Assignments
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        className="bg-dark full-height justify-content-center align-items-center "
        fluid
      >
        <Row>
          <Col align="center">
            <div className="chat-box-container">
              <div id="chatInputBox" className="text-white bg-dark">
                <span id="displayedText" data-testid="displayedText">
                  {displayedText}
                </span>
              </div>
              &nbsp; &nbsp;
              {responseText && (
                <div className="bg-accent1 text-white H-100 response-text">
                  {responseText}
                </div>
              )}
              <div className="mt-3 mb-3">
                <Button variant="primary" href="./hireme">
                  Find out More
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        className="bg-white full-height d-flex justify-content-center"
        fluid
      >
        <Row>
          <Col>
            <Container>
              <Row>
                <Col className="text-accent1" align="center">
                  &nbsp;
                  <h1 className="text-accent1">My Contributions in Action</h1>
                  &nbsp;
                </Col>
              </Row>
            </Container>
            <Container>
      <Row>
        <Col>
          <Carousel 
            ref={carouselRef}
            indicators={true}
            interval={5000}
            keyboard={true}
            pauze="hover"
            touch={true}
            >
            {relevantExperience
              .sort((a, b) => a.rank - b.rank)
              .map((experience, index) => {
                if (experience) {
                  return (
                    <Carousel.Item key={`SuccessStory_${index}`}>
                      <div className="text-center p-4">
                        <h3 className="text-secondary">{experience.title}</h3>
                        <p className="text-accent3">{experience.description}</p>
                      </div>
                    </Carousel.Item>
                  );
                }
                return null;
              })}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <div className="mt-3 mb-3">
            <Button variant="primary" href="./hireme">
              Interested to find out how I can help you? Let's connect!
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
          </Col>
        </Row>
      </Container>
      <Connect />
    </>
  );
}
