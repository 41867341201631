import React, { useCallback } from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

export default function Connect() {
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [status, setStatus] = useState(null);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleStatusModalClose = useCallback(() => {
    setShowStatusModal(false);
    if (status === "Email sent successfully!") {
      setShowModal(false);
    }
  }, [status]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      //console.log("handling submit");
      fetch("https://formspree.io/f/mdknzjye", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: name, email: email, question: question }),
      })
        .then((response) => {
          
          if (response.ok) {
            //console.log(response);
            setStatus("Email sent successfully!");
            setShowStatusModal(true);
            setName(""); // Clear the name input field
            setEmail(""); // Clear the email input field
            setQuestion(""); // Clear the question textarea
          } else {
            setStatus("Failed to send email. Please try again.");
            setShowStatusModal(true);
          }
        })
        .catch((error) => {
          //handleModalClose();
          setStatus("Error: " + error.message);
          setShowStatusModal(true);
        });
    },
    [name, email, question]
  );

  const renderLinkedinTooltip = (props) => (
    <Tooltip id="linkedin-tooltip" {...props}>
      Visit my LinkedIn!
    </Tooltip>
  );

  const renderCalendlyTooltip = (props) => (
    <Tooltip id="calendly-tooltip" {...props}>
      Schedule a meeting with me!
    </Tooltip>
  );

  const renderEmailTooltip = (props) => (
    <Tooltip id="email-tooltip" {...props}>
      Send me a message
    </Tooltip>
  );

  return (
    <>
      <Modal show={showModal} onHide={() => handleModalClose()} centered data-testid="emailModal">
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>New Message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-primary">
          <Form onSubmit={handleSubmit}>
            <Form.Control
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="bg-primary border-white text-white"
              required
            />
            <br />
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-primary border-white text-white"
              required
            />
            <br />
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Message"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="bg-primary border-white text-white"
              required
            />
            <br />
            <div className="text-center">
              <Button variant="dark" type="submit" className="mt-3">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Status Modal */}
      <Modal 
        show={showStatusModal}
        onHide={handleStatusModalClose}
        centered
        data-testid="statusModal"
      >
        <Modal.Header closeButton className="bg-light text-accent1">
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light text-accent1">
          <p>{status}</p>
        </Modal.Body>
        <Modal.Footer className="bg-light text-accent1">
          <Button variant="dark" onClick={handleStatusModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>


      <Container
        fluid
        className="p-0 bg-secondary text-dark full-height d-flex justify-content-center align-items-center "
      >
        <Row>
          <Col>
            <br />
            <table>
              <tbody >
                <tr>
                  <td colSpan="3">
                    <h1 className="text-center">
                      Connect with me using one of the following options:
                    </h1>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderLinkedinTooltip}
                    >
                      <a
                        data-testid="linkedinIcon"
                        href="https://www.linkedin.com/in/rickpmolenaar/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40px"
                          height="40px"
                          fill="#004b84"
                          className="bi bi-linkedin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                        </svg>
                      </a>
                    </OverlayTrigger>
                  </td>
                  <td align="center">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderCalendlyTooltip}
                    >
                      <a
                        data-testid="calendlyIcon"
                        href="https://calendly.com/rickpmolenaar"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="48px"
                          viewBox="0 -960 960 960"
                          width="48px"
                          fill="#004b84"
                        >
                          <path d="M596.82-220Q556-220 528-248.18q-28-28.19-28-69Q500-358 528.18-386q28.19-28 69-28Q638-414 666-385.82q28 28.19 28 69Q694-276 665.82-248q-28.19 28-69 28ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Z" />
                        </svg>
                      </a>
                    </OverlayTrigger>
                  </td>
                  <td align="center">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderEmailTooltip}
                    >
                      <div data-testid="emailMeIcon" onClick={() => setShowModal(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="48px"
                          viewBox="0 -960 960 960"
                          width="48px"
                          fill="#004b84"
                        >
                          <path d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-302L140-685v465h680v-465L480-462Zm0-60 336-218H145l335 218ZM140-685v-55 520-465Z" />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
