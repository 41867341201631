import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import roleOrServices from "./content/roleorservice.json";
import RoleOrServiceCard from "./roleorservicecard";

export default function HireMe() {
  return (
    <>
      <>
        <Container fluid>
          <Row className="mt-3 mb-3 mx-3">
            <Col lg={9} className="mt-3 msb-2">
              <h1 className="text-accent1">Hire me</h1>
              <p className="text-accent3">
                I specialize in interim and fractional leadership, as well as
                change management, to help organizations address complex
                software engineering organizational and process challenges.
                Whether it’s aligning team structures with strategic goals,
                streamlining delivery workflows, or fostering collaboration
                during transitions, I bring hands-on experience and a steady
                presence to guide teams through periods of change.
              </p>
              <p className="text-accent3">
                From stabilizing operations and mentoring engineering leaders to
                defining clear performance expectations and driving technical
                strategy, my focus is on building resilient, high-performing
                teams and enabling sustainable improvements that deliver real
                results.
              </p>
              <p className="text-accent3">
                Please consider the following options or{" "}
                <a href="./connect">contact me</a> to directly discuss
              </p>
            </Col>
            <Col lg={3}>
              <div className="text-center mt-3 mb-3">
                <img
                  src="./images/pexels-pixabay-276272.jpg"
                  alt="Orchestrating Transitions"
                  className="circular-image"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-1 ms-1">
            {roleOrServices.map((roleOrService, index) => (
              <Col
                data-testid="roleorservice-card"
                sm={12}
                md={7}
                lg={4}
                className="mb-5"
                key={"roleorservice_" + index}
              >
                <RoleOrServiceCard data={roleOrService} />
              </Col>
            ))}
          </Row>
        </Container>
      </>
    </>
  );
}
