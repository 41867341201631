import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Home from "./home";
import Connect from "./connect";
import { content } from "./content";
import Article from "./article";
import Articles from "./articles";
import HireMe from "./hireme";
import RoleOrService from "./roleorservice";
import rolesOrServicesData from "./content/roleorservice.json";
import AboutMe from "./aboutme";

import "./custom.scss";

function App({ router: Router = BrowserRouter }) {
  return (
    <Router>
      <div className="montserrat-rpm">
        <Navbar bg="dark" expand="md" sticky="top" className="navbar-dark">
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="me-1 ms-1"
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="me-1 ms-1">
            <Navbar.Brand className="ms-1" as={Link} to="/">
              Rick Molenaar
            </Navbar.Brand>
            <Nav className="me-auto">
              <NavDropdown
                title="Hire Me"
                id="servicesDropDownMenu"
                data-testid="hireMeDropDownMenu"
                show={process.env.NODE_ENV === 'test' ? true : undefined}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/hireme"
                  key="hiremeDropDownItem_summary"
                  data-testid="hiremeDropDownItem_summary"
                >
                  All Services
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {rolesOrServicesData.map((roleOrService, index) => (
                  <NavDropdown.Item
                    as={Link}
                    to={"/hireme/" + roleOrService.title}
                    key={"roleOrServiceDropDownItem_" + index}
                    data-testid={"roleOrServiceDropDownItem_" + index}
                  >
                    {roleOrService.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown
                title="Articles"
                id="articlesDropDownMenu"
                data-testid="articlesDropDownMenu"
                show={process.env.NODE_ENV === 'test' ? true : undefined}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/articles"
                  key="articleDropDownItem_summary"
                  data-testid="articleDropDownItem_summary"
                >
                  Summaries
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {content.articles.map((article, index) => (
                  <NavDropdown.Item
                    as={Link}
                    to={"/articles/" + article.link}
                    key={"articleDropDownItem_" + index}
                    data-testid={"articleDropDownItem_" + index}
                  >
                    {article.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <Nav.Link as={Link} to="/aboutme" data-testid="aboutmeMenu">
                About Me
              </Nav.Link>
              <Nav.Link as={Link} to="/connect" data-testid="connectMenu">
                Connect
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="hireme" element={<HireMe />} />
          <Route path="hireme/:id" element={<RoleOrService />} />
          <Route path="articles" element={<Articles />} />
          <Route path="articles/:id" element={<Article />} />
          <Route path="aboutme" element={<AboutMe />} />
          <Route path="connect" element={<Connect />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
