import React from "react";
import { Card } from 'react-bootstrap';
import { showInParagraphs } from "./utils";

export default function RoleOrServiceCard({ data }) {
    const handleClick = () => {
        window.location.href = "hireme/" + data.title; // or use a router push if using React Router
      };

    return (
        <Card className = "h-100 shadow-sm bg-white rounded">
            {/* <Card.Img variant="top" src={"./images/"+ data.image} /> */}
            <Card.Body className="d-flex flex-column">
                <Card.Title className="mb-0 font-weight-bold text-accent2" onClick={handleClick}>{data.title}</Card.Title>
                <hr/>

                <div className="text-accent3" onClick={handleClick}>{showInParagraphs(data.description)}</div>
                <a href={"hireme/" + data.title} className="btn btn-dark mt-auto font-weight-bold">Find out more</a>
            </Card.Body>
        </Card>
    )
}